<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: [],
  data() {
    return {};
  },
  // created () {},
  mounted() {
    if (this.$judgePhone() === 'A' && this.$store.state.first) {
      this.$store.commit('setFirst', false);
      setTimeout(() => {
        this.$router.replace('/home1');
      }, 1500);
    } else {
      this.$router.replace('/home1');
    }
  },
  // methods: {},
  // watch: {},
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less"></style>
